import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/ux/uxbanner.jpg";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Casestudy
import downloadFile from "../../../documents/UX-Portfolio.pdf";

import caseStudyImgOne from "../../../images/services/ux/gif/1-1-information-architecture.gif";
import caseStudyImgTwo from "../../../images/services/ux/gif/1-2-ui-design.gif";
import caseStudyImgThree from "../../../images/services/ux/gif/1-3-design-system.gif";
import caseStudyImgFour from "../../../images/services/ux/gif/1-4-prototypes.gif";
import caseStudyImgFive from "../../../images/services/ux/gif/2-1-accessibility.gif";
import caseStudyImgSix from "../../../images/services/ux/gif/2-2-resource.gif";
import caseStudyImgSeven from "../../../images/services/ux/gif/3-1-frontend.gif";
import caseStudyImgEight from "../../../images/services/ux/gif/3-2-cms.gif";
import caseStudyImgNine from "../../../images/services/ux/gif/3-3-postlaunch.gif";
import caseStudyImgTen from "../../../images/services/ux/gif/4-1-branding.gif";
import caseStudyImgEle from "../../../images/services/ux/gif/4-2-artwork.gif";
import caseStudyImgTwe from "../../../images/services/ux/gif/4-3-marketing.gif";

// Testimonial
import TabcmpSet from "../../../components/tab/TabcmpSet";
import ClientTestimonial1 from "../../../components/ClientTestimonialKentBoltonFoodini";
import ClientTestimonial8 from "../../../components/ClientTestimonialMandeepEffi";
import ClientTestimonial3 from "../../../components/ClientTestimonialGaryPokitpal";
import ClientTestimonial2 from "../../../components/ClientTestimonialErajLilardia";
import KentBolton from "../../../images/Clients/KentBolton.png";
import MandeepSodhi from "../../../images/Clients/MandeepSodhi.jpg";
import GaryCobain from "../../../images/Clients/GaryCobain.jpeg";
import ErajRatnayake from "../../../images/Clients/ErajRatnayake.jpg";

// Contact
import UserCard from "../../../components/UserCard";
import SvgIconsSet from "../../../components/SvgIconsSet";
import Sam from "../../../images/services/ux/Sam.png";

const UX = () => {
  const accordianList = [
    {
      id: 1,
      heading: "User Experience (UX) & Design",
      breif:
        "We design intuitive interfaces and user journeys, define a cohesive structure for your information, and craft beautiful and functional interfaces. We also ensure design consistency with comprehensive design systems to support future scalability. Need to envision your product without getting developers involved? We create interactive prototypes that can communicate your ideas to stakeholders and investors at a fraction of the cost of full-scale development.",
      subsections: [
        {
          contentid: "sub1-1",
          img: caseStudyImgOne,
          subheading: "Information Architecture & Navigation",
          content:
            "We define the organization and structure of your digital product's information, ensuring a logical and intuitive user journey. This includes creating sitemaps, wireframes, and navigation systems.",
          deliverables: "Sitemaps, wireframes, navigation prototypes.",
        },
        {
          contentid: "sub1-2",
          img: caseStudyImgTwo,
          subheading: "User Interface Design",
          content:
            "We craft visually appealing and user-friendly interfaces that are both aesthetically pleasing and functional. We prioritize visual hierarchy, clear calls to action, and consistent design elements.",
          deliverables:
            "Mockups, prototypes, style guides, UI component libraries.",
        },
        {
          contentid: "sub1-3",
          img: caseStudyImgThree,
          subheading: "Design System Development",
          content:
            "We create a comprehensive design system that streamlines your design process and ensures consistency across all your digital products. This includes UI component libraries, style guides, and design tokens.",
          deliverables:
            "Design system documentation, UI component libraries, style guides.",
        },
        {
          contentid: "sub1-4",
          img: caseStudyImgFour,
          subheading: "Interactive Prototypes",
          content:
            "We build interactive prototypes that simulate the functionality of your digital product, allowing for user testing and refinement before development. This ensures a smooth and intuitive user experience, while also providing you with a deliverable that can be readily used to demo your product to investors, development teams and other stakeholders.",
          deliverables:
            "Tools for Investor Demos such as Interactive Prototypes, Click-throughs ",
        },
        // Add more subsections as needed
      ],
    },
    {
      id: 2,
      heading: "UX Consultancy and Resourcing",
      breif:
        "We offer UX expertise to elevate your digital products. Get a UX and Accessibility Audit to identify usability issues or leverage Resource Augmentation to embed skilled UX Designers in your team.",
      subsections: [
        {
          contentid: "sub1-1",
          img: caseStudyImgFive,
          subheading: "UX and Accessibility Audit",
          content:
            "We conduct a comprehensive evaluation of your existing website or app to identify usability issues and accessibility barriers. This includes analyzing user flows, content, navigation, and adherence to WCAG guidelines.",
          deliverables:
            "Audit report with detailed findings and recommendations for improvements.",
        },
        {
          contentid: "sub1-2",
          img: caseStudyImgSix,
          subheading: "Resource Augmentation",
          content:
            "Do you have a fully-fledged development team, but are lacking in-house UX expertise? We offer resource augmentation, providing skilled UX Designers to work alongside your team members as your own direct resource, filling in any gaps in your UX requirements to take your products and services to the next level.",
          deliverables: "Dedicated UX Resource",
        },
        // Add more subsections as needed
      ],
    },
    {
      id: 3,
      heading: "Web Development & Content Management",
      breif:
        "We translate UX designs into functional websites and apps, with a team of highly-skilled engineers that have a keen eye for ensuring the sanity of the original design.  Get your website up and running quickly by leveraging and customising CMS solutions and get support to maintain and improve your website with the aid of analytics tools and comprehensive reports.",
      subsections: [
        {
          contentid: "sub1-1",
          img: caseStudyImgSeven,
          subheading: "Front-end Development",
          content:
            "We translate your static design into a functional and responsive website or app using modern front-end technologies like HTML, CSS, and JavaScript. We ensure cross-browser compatibility and optimal performance.",
          deliverables:
            "Design-compatible front-end code, responsive website/app.",
        },
        {
          contentid: "sub1-2",
          img: caseStudyImgEight,
          subheading: "CMS Configuration and Customisation",
          content:
            "We help to setup Site Builders and Content Management Systems like Wordpress, Wix and Webflow, enabling you to get your online presence up and running in rapid time. We aid in configuring custom themes and templates, while applying your unique branding and customisations to ensure your website is one of a kind.",
          deliverables:
            "CMS-enabled website with minimal to no code maintenance.",
        },
        {
          contentid: "sub1-2",
          img: caseStudyImgNine,
          subheading: "Post-Launch Support & Analytics",
          content:
            "We provide ongoing support after launch to monitor user behavior, gather feedback, and iterate on the design based on data insights. This ensures continuous improvement and user satisfaction.",
          deliverables:
            "Site usage analysis report including action points for improvements",
          tools: "User analytics platforms, heatmaps, user session recording",
        },
        // Add more subsections as needed
      ],
    },
    {
      id: 4,
      heading: "Branding & Marketing Material",
      breif:
        "Establish a cohesive brand, create unique visuals and craft compelling marketing assets that will boost your corporate identity and take your product or service to the next level.",
      subsections: [
        {
          contentid: "sub1-1",
          img: caseStudyImgTen,
          subheading: "Branding and Style Guidelines",
          content:
            "We establish a clear and consistent brand identity for your digital product, including defining logos, typography, color palettes, iconography, and layout principles. This ensures a cohesive and recognizable brand experience.",
          deliverables: "Brand style guide, Logo design, Brand assets library",
        },
        {
          contentid: "sub1-2",
          img: caseStudyImgEle,
          subheading: "Artwork logos and Illustrations",
          content:
            "We create custom artwork and illustrations that enhance your brand identity and visually engage your users. This includes designing bespoke icons, infographics, and other visual elements, that can elevate your digital presence and make it uniquely yours.",
          deliverables: "High-quality vector artwork illustrations",
        },
        {
          contentid: "sub1-2",
          img: caseStudyImgTwe,
          subheading: "Marketing Material",
          content:
            "We design visually appealing and engaging marketing materials like landing pages, email templates, social media graphics, and presentations to support your marketing efforts.",
          deliverables:
            "Business Cards, Flyers, Brochures, Social Media graphics, Power Point Presentation templates, and other Marketing collateral",
        },
        // Add more subsections as needed
      ],
    },
    // Add more accordion items as needed
  ];
  const tabData = [
    {
      label: "Kent Bolton",
      text: "Foodini",
      image: KentBolton,
      content: <ClientTestimonial1 />,
    },
    {
      label: "Mandeep Sodhi",
      text: "Effi",
      image: MandeepSodhi,
      content: <ClientTestimonial8 />,
    },
    {
      label: "Gary Cobain",
      text: "PokitPal",
      image: GaryCobain,
      content: <ClientTestimonial3 />,
    },
    {
      label: "Eraj Ratnayake",
      text: "Lilardia Capital",
      image: ErajRatnayake,
      content: <ClientTestimonial2 />,
    },
  ];

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile]);

    const formData = { ...values };
    formData.Message = "UX-Portfolio";
    formData.FormType = "CaseStudy";
    formData.Subject = "USER EXPERIENCE PORTFOLIO";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="service-page ux-service">
      <Seo
        title="UI & UX Design"
        description="iTelaSoft is an Australian-owned company providing IT services and
              software product development. We specialise in strategy
              consultation, design, development and management of products. We
              help entrepreneurs transform ground breaking ideas into successful
              solutions."
      />

      <IntroBanner
        title="UI & UX Design"
        subtitle="We are a passionate team of UX/UI designers driven by a shared mission to create intuitive and engaging digital experiences that empower users and deliver tangible business value. We believe in the power of human-centered design to solve problems, improve user satisfaction, and ultimately, drive business growth."
        image={Banner}
      />

      <section className="our-services py-6">
        <Container>
          <div className="title title-1">Our Services</div>
          <Row className="mt-2">
            <Col>
              <div className="services-accordian">
                <Accordion allowMultipleExpanded>
                  {accordianList.map(({
                    id, heading, breif, subsections,
                  }) => (
                    <AccordionItem key={id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <Row className="accordian-heading">
                            {heading}
                            <div className="plus">
                              <SvgIconsSet.DownArrow />
                            </div>
                            <div className="minus">
                              <SvgIconsSet.UpArrow />
                            </div>
                          </Row>
                          <Row className="accordian-breif">{breif}</Row>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordian-content">
                          {subsections.map(
                            ({
                              contentid,
                              img,
                              subheading,
                              content,
                              deliverables,
                            }) => (
                              <Col key={contentid}>
                                <Row className="py-2">
                                  <Col md={6} lg={4}>
                                    <img
                                      className="content-img"
                                      src={img}
                                      alt={`${subheading}`}
                                    />
                                    {/* <video loop autoPlay muted controls>
                                  <source src={caseStudyVideo} type="video/mp4" />
                                </video> */}
                                  </Col>
                                  <Col
                                    md={6}
                                    lg={8}
                                    className="accordian-subcontent"
                                  >
                                    <h5>{subheading}</h5>
                                    <p>{content}</p>
                                    <strong>Deliverables</strong>
                                    <p>{deliverables}</p>
                                    {/* <strong>Tools*</strong>
                                <p>{tools}</p> */}
                                  </Col>
                                </Row>
                              </Col>
                            ),
                          )}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-0">
        <div className="section-padding wrap-contrast-section download-portfolio consultation">
          <Container>
            <div className="contrast-section">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="flex-column">
                        <div className="contrast-section-title">
                          Unlock
                          {" "}
                          <span className="text-yellow">
                            the full potential
                          </span>
                          {" "}
                          of your digital products with first-class UX expertise
                        </div>
                        <div className="contrast-section-text">
                          Download our portfolio for a sneak peek at our
                          process, expertise, and stunning projects. See UX come
                          to life and get inspired for your own creation.
                          It&apos;s UX design, simplified.
                        </div>
                        <div className="mt-2 mb-3 mx-1">
                          <p>
                            <b>Download UX Portfolio Now!</b>
                          </p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="email-area col-lg-4 col-md-6 col-sm-6 col-12 px-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mb-2 ${
                                        subscriptions.hasValidationErrors ||
                                        subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download UX Portfolio
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />
                          <p className="small-text">
                            By downloading this case study, you agree to receive
                            emails from iTelaSoft about products, services, and
                            updates. You can unsubscribe at any time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section>
        <Container>
          <div className="section-padding wrap-our-process">
            <h3 className="title-2-1">Onboarding Process</h3>
            <h2 className="title-1 mb-2">Our Approach</h2>
            <p>
              <span className="font-weight-bold">Flexibility is key!</span>
              {" "}
              While we&apos;ve outlined our standard process below, we
              understand that every project is unique. We&apos;re flexible and
              adaptable, ready to tailor our approach to suit your specific
              needs and the nature of your project. Let&apos;s collaborate to
              create a customized plan that fits perfectly with your vision and
              goals.
            </p>
            <div className="our-process mt-3">
              <Row>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">01</h1>
                      <CardTitle tag="h5">Getting to Know You</CardTitle>
                      <CardText>
                        First, we have a discussion to learn about your business
                        goals, who you&apos;re targeting, and what you need from
                        your project. It&apos;s like a friendly chat to get to
                        know each other better!
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">02</h1>
                      <CardTitle tag="h5">Planning Your Journey</CardTitle>
                      <CardText>
                        Next, we come up with a plan that fits your goals. We
                        think about who will be using your product and what
                        they&apos;ll need, so we can make something that works
                        just right for both you and your users.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">03</h1>
                      <CardTitle tag="h5">Let&apos;s Get Creative</CardTitle>
                      <CardText>
                        Now comes the fun part! We gather around and throw out
                        all sorts of ideas. We brainstorm and let our
                        imaginations run wild to come up with the best ideas for
                        your project.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">04</h1>
                      <CardTitle tag="h5">Trying Things Out</CardTitle>
                      <CardText>
                        Once we have some ideas, we put them to the test. We
                        make mock-ups of what your product might look like and
                        see how they feel. It&apos;s like trying on different
                        outfits until we find the perfect one!
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">05</h1>
                      <CardTitle tag="h5">Testing, Testing</CardTitle>
                      <CardText>
                        We don&apos;t just trust our instincts - we put our
                        designs to the test! We ask real people to try out what
                        we&apos;ve made and tell us what they think, to make
                        sure we&apos;re on the right track.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">06</h1>
                      <CardTitle tag="h5">Putting It All Together</CardTitle>
                      <CardText>
                        Once we know what works best, we start putting
                        everything together. Our UX designers work closely with
                        the tech-folk to make sure everything looks and feels
                        just right.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">07</h1>
                      <CardTitle tag="h5">Launching Your Project</CardTitle>
                      <CardText>
                        It&apos;s time to show off your project to the world! We
                        help you launch it and stick around to make sure
                        everything goes smoothly.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card className="process-card">
                    <CardBody>
                      <h1 className="font-weight-bold">08</h1>
                      <CardTitle tag="h5">Keeping Things Awesome</CardTitle>
                      <CardText>
                        Even after your project is live, we&apos;re still here
                        to help! We keep an eye on how things are going and make
                        tweaks as needed, so your project stays awesome.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>

      {/* Take this section to the new landing page. */}
      <section className="pb-0">
        <Container fluid className="p-0">
          <div className="section-padding wrap-contrast-section consultation">
            <Container>
              <div className="contrast-section">
                <div className="mr-2">
                  <div className="contrast-section-title">
                    Free Consultation
                  </div>
                  <div className="contrast-section-text">
                    Get 2 free hours of initial consultation to learn about your
                    business goals, who you&apos;re targeting, and what you need
                    from your project. It&apos;s like a friendly chat to get to
                    know each other better!
                  </div>
                </div>
                <div className="getting-touch-right ml-1">
                  <a
                    className="btn btn-primary btn-icon-button"
                    href="/contact/"
                  >
                    <span className="btn-text">Get in touch</span>
                    <span className="btn-icon">
                      <SvgIconsSet.SideArrow />
                    </span>
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </Container>
      </section>

      <section className="client-testimonial-section">
        <Container>
          <TabcmpSet tabData={tabData} tabNum={4} />
        </Container>
      </section>

      <section className="pt-0">
        <Container fluid className="p-0">
          <div className="section-padding wrap-contrast-section get-in-touch">
            <Container>
              <div className="contrast-section">
                <div className="mr-2">
                  <div className="contrast-section-title">
                    Join our esteemed roster of clients and let&apos;s embark on
                    an exciting journey towards success, innovation, and growth.
                  </div>
                  <div className="contrast-section-text">
                    Get 2 free hours of initial consultation.
                  </div>
                </div>
                <div className="getting-touch-right ml-1">
                  <a
                    className="btn btn-primary btn-icon-button"
                    href="/contact/"
                  >
                    <span className="btn-text">Get in touch</span>
                    <span className="btn-icon">
                      <SvgIconsSet.SideArrow />
                    </span>
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </Container>
      </section>

      <section className="section-contact">
        <Container>
          <Row>
            <Col md={9} lg={9} xl={9} className="title-section flex-column">
              <div className="title title-1 desk">Let us know how to help</div>
              <div className="title title-1 mobile">
                Let us know how to help
              </div>
              <div>
                <p>
                  We love to hear what challenges you go through in your
                  business and discuss how we can assist you.
                </p>
              </div>
              <div className="getting-touch-right">
                <a className="btn btn-primary btn-icon-button" href="/contact/">
                  <span className="btn-text">Get in touch</span>
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              className="mb-2 contact-card-section col-6"
            >
              <a
                className="contact-card"
                href="/contact/"
                aria-label="Samanalie Jayasinha"
              >
                <UserCard
                  name="Samanalie Jayasinha"
                  detail="UX and Design Adviser"
                  image={Sam}
                  link="https://www.linkedin.com/in/samanaliedj/"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default UX;
