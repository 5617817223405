import React from "react";
import Foodini from '../images/home-page/logos-png/Foodini.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-kent"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“Sam and her team were very easy to work with; clear communications, very positive attitude and above all knowledgeable and offered great suggestions. Sam immediately understood the brief, and collaborated well on the designs. Awesome team and wonderful professional result.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Kent Bolton</h6>
                            <p>Foodini</p>
                        </div>
                        <div>
                            <img className="" alt="foodini-logo" src={Foodini} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
